import React from "react";
import "./CloudComputing.css";
import costumerImg from "../../Images/costumer_6012670.png";
import logoImage from "../../Images/Logo.png";
import gcp from "../../Images/GCP end to end consultation.png";
import dataAnalystics from "../../Images/Data Analytics.png";
import businessIntelligence from "../../Images/Business Intelligence.png";
import azure from "../../Images/Azure.png";
import DataWareHouse from "../../Images/Data Warehouse and ETL.png";
import img4 from "../../Images/04.jpg";
import img5 from "../../Images/05.jpg";
import googleImg from "../../Images/google_cloud_4x3_2x1_Small.jfif";
import awsImg from "../../Images/aws_4x3_2x1_Large.jfif";
import microsoftImg from "../../Images/Microsoft_4x3_2x1_Small.jfif";
import peopleIMg from "../../Images/full-shot-people-office.jpg";
import { useNavigate } from "react-router-dom";

const CloudComputing = () => {
    const navigate = useNavigate();
  
  const handleContactClick = () => {
    navigate("/contact");
  };
  return (
    <div>
      {/* Breadcrumb Section */}
      <section className="fir-sec">
        <div className="path">
          <div className="main-path">
            <span className="design-1">Home &gt; Services &gt;</span>
            <span className="design-2">Cloud Computing</span>
          </div>
        </div>
      </section>

      {/* Quality Assurance Section */}
      <section className="sen-sec">
        <div className="aligngment">
          <div className="qa-section">
            <div className="qa-text">Cloud Computing</div>
          </div>
        </div>
      </section>

      {/* QA Content Section */}
      <section className="thi-sec">
        <div className="ali">
          <div className="qa-content">
            <div className="qa-left">
              <p>
                With over 10+ Experienced and Certified Cloud specialists,
                Novamic Technologies provides solutions backed by extensive
                cloud management expertise. Our numerous AWS competencies and
                Service Delivery Partnerships affirm our position as a leading
                cloud engineering partner for businesses across various sectors
              </p>
            </div>
            <div className="qa-right">
              <p>
                We are dedicated to ensuring seamless operations, optimizing
                cloud expenses, and enhancing customer delight with an
                exceptional user experience.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="fourth-sect">
        <div className="">
          <div className="features-section">
            <div className="feature-container ali">
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 1 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 2 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 3 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Responsive Content Section */}
      <section className="fifth-sect">
        <div className="ali">
          <h1 className="hea">Cloud Computing</h1>
          <div clasNames="responsive-section">
            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img src={azure} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">Azure</h2>
                <p className="content-text">
                  Our Azure services empower businesses with a comprehensive
                  suite of solutions, leveraging technologies such as Azure
                  Virtual Machines, Azure Kubernetes Service (AKS), and Azure
                  DevOps to streamline operations and enhance productivity. We
                  provide seamless cloud migration, leveraging Azure Site
                  Recovery and Azure Migrate to ensure a smooth transition. Our
                  team of experts configures and manages Azure SQL Database and
                  Azure Cosmos DB for scalable, high-performance data solutions.
                  With Azure Active Directory and Azure Security Center, we
                  ensure robust security and compliance, safeguarding your
                  digital assets. Additionally, our services include
                  implementing Azure Logic Apps and Azure Functions for
                  efficient automation and workflow optimization, delivering
                  tailored solutions that meet your
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container">
              <div className="responsive-image">
                <img src={gcp} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">GCP end to end consultation</h2>
                <p className="content-text">
                  Our Google Cloud Platform (GCP) services encompass
                  implementing Cloud Functions and Cloud Composer for
                  streamlined automation and workflow management, delivering
                  customized solutions that align with your specific business
                  requirements. We facilitate seamless cloud migration with
                  tools like Migrate for Compute Engine and Transfer Appliance,
                  ensuring a smooth and efficient transition. Our experts manage
                  Cloud SQL and BigQuery for powerful, scalable data management
                  and analytics. With Cloud Identity and Access Management (IAM)
                  and Cloud Security Command Center, we provide top-tier
                  security and compliance, protecting your digital
                  infrastructure. Your preferred partner in Cloud Migration: -
                  <ul class="content-text">
                    <li>
                      • Begin your cloud adoption journey with Novamic's Cloud
                      Migration Services.
                    </li>
                    <li>
                      • Craft and implement the ideal migration strategy
                      tailored to your enterprise.
                    </li>
                    <li>
                      • Simplify cloud migration complexities with our expert
                      assessments and smooth workload migration capabilities.
                    </li>
                    <li>
                      • Novamic creates secure, scalable, and repeatable cloud
                      environments on your preferred cloud provider.
                    </li>
                  </ul>
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img src={dataAnalystics} alt="Description of the image" />
              </div>

              <div className="responsive-content">
                <h2 className="content-heading">Data Analytics</h2>
                <p className="content-text">
                  Our Big Data solutions are designed to maximize your return on
                  investment. Through a consultative approach, we assist you in
                  making informed decisions that optimize business processes,
                  avoiding unnecessary financial strain on your organization. We
                  facilitate seamless data collaboration across your
                  organization by storing information in a unified data
                  warehouse, accessible only through strong authentication
                  measures and easy accessibility this data via interactive
                  dashboards, promoting transparency and efficiency. Our big
                  data analytics team rigorously monitors data quality during
                  processing and storage, adhering to strict governance
                  policies. This ensures the integrity of business data and
                  provides actionable insights to promote innovation and drive
                  growth.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container">
              <div className="responsive-image">
                <img
                  src={businessIntelligence}
                  alt="Description of the image"
                />
              </div>

              <div className="responsive-content  ">
                <h2 className="content-heading">Business Intelligence</h2>
                <p className="content-text">
                  We offer comprehensive Business Intelligence (BI) services
                  designed to transform your raw data into actionable insights.
                  Our solutions encompass everything from data integration and
                  warehousing to advanced analytics and real-time reporting. By
                  leveraging cutting-edge BI tools and technologies, we enable
                  your organization to make informed decisions, optimize
                  operations, and uncover new opportunities for growth. Through
                  interactive dashboards and intuitive reporting, we provide a
                  clear and concise view of your business metrics, empowering
                  stakeholders at all levels to make data-driven decisions.
                  Whether you need to identify market trends, monitor
                  performance, or streamline processes, our BI services are
                  designed to provide you with the insights needed to stay
                  competitive and achieve your business goals.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img src={DataWareHouse} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">Data Warehouse and ETL</h2>
                <p className="content-text">
                  Our Data Warehouse and ETL (Extract, Transform, Load) services
                  are designed to streamline the process of data consolidation
                  and management. By extracting data from one or multiple
                  databases, transforming it to meet your business requirements,
                  and loading it into a centralized data warehouse, we ensure
                  that data is consistently organized and readily accessible.
                  This centralized repository enables organisations to integrate
                  data from various sources, providing a unified view of your
                  business operations and facilitating more informed
                  decision-making. Our ETL processes are meticulously designed
                  to handle large volumes of data efficiently, ensuring data
                  integrity and quality throughout the transformation and
                  loading stages.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sixth-sect">
        <div className="ali pop">
          <div className="responsive-content-1">
            <span className="quotes">"</span>
            <p className="content-text-1">
              Working with Kyndryl to move to SAP S/4HANA was one of the
              smoothest projects I've ever been involved with. Even though we
              were contending with the impacts of COVID-19 throughout the
              engagement, Kyndryl mobilized its full global resources to help us
              stay on track and go live on time.
            </p>
            <span className="quotes">"</span>
          </div>
          <h1 className="hed-12">Rainer Steffl</h1>
          <p className="para-12">CIO, Mondi Group</p>
        </div>
      </section>

      <section className="seventh-sect">
        <div className="ali flexi">
          <div className="partnerships-text">
            <h2>Partnerships</h2>
            <p>Learn more about some of our key partnerships</p>
          </div>
          <div className="partnerships-logos">
            <img src={googleImg} alt="Google Cloud" />
            <img src={microsoftImg} alt="Microsoft" />
            <img src={awsImg} alt="AWS" />
          </div>
        </div>
      </section>

      <section className="Eighth-sec">
        <div className="ali">
          <div className="responsive-container-19">
            <div className="responsive-image">
              <img src={peopleIMg} alt="Description of the image" />
            </div>

            <div className="responsive-content ">
              <h2 className="kok">
                Get a 30-minute, no-cost strategy session with an Application
                services expert
              </h2>

              <a onClick={handleContactClick} className="content-button">
                Let's Talk &rarr;
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-sec">
        <div className="alingment-8588">
          <footer className="footer">
            <div className="footer-logo-section">
              <div className="footer-logo">
                <img src={logoImage} alt="Novamic Logo" />
              </div>
              <p className="footer-description">
                Novamic Technologies was established in order to develop
                creative digital platforms of technology and information.
              </p>
            </div>

            <div className="footer-links-section">
              <h4>Quick Links</h4>
              <ul className="jojo">
                <li>
                  <a href="#about">About us</a>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
                <li>
                  <a href="#career">Career</a>
                </li>
              </ul>
            </div>

            <div className="footer-newsletter-section">
              <h4>News Letter</h4>
              <p className="footer-newsletter-text">
                Don’t miss to subscribe to our new feeds, kindly fill the form
                below:
              </p>
              <form className="newsletter-form">
                <input
                  type="email"
                  className="newsletter-input"
                  placeholder="Email Address"
                  required
                />
                <button type="submit" className="newsletter-button">
                  →
                </button>
              </form>
              <div className="footer-social-links">
                <a href="#" target="_blank">
                  LinkedIn
                </a>
              </div>
            </div>
          </footer>
        </div>
      </section>
    </div>
  );
};

export default CloudComputing;
