import React from "react";
import "./TechnologyImpactSection.css"; // Assuming the CSS is stored in a separate file.
import AiImg from "../../Images/aiTechnologyImg.jpg";
import menImg from "../../Images/menGlassesImg.jpeg";
import infinityImg from "../../Images/infinityImg.jpeg";
import videoPauseImg from "../../Images/videoPauseImg.png";

const TechnologyImpactSection = () => {
  return (
    <section className="sixth-sec">
      <div className="video-sec">
        <div className="video-text">
          <div className="intro">
            <h1>Accelerate the impact of technology across your business</h1>
            <p>
              Kyndryl Bridge is an open integration platform that leverages
              Kyndryl’s core strengths — data-driven insights and decades of
              expertise — to give enterprises visibility across their technology
              estates with actionable insights that help understand, predict,
              and act for better business outcomes.
            </p>
          </div>

          <div className="video-container">
            <div className="video-wrapper">
              <img src={videoPauseImg} alt="Video Thumbnail" />
            </div>
          </div>
        </div>

        <div className="services-section">
          <div className="services-content">
            <h2>Our services</h2>
            <h3>Building healthy digital systems that thrive on change</h3>
            <p>
              From Cloud to Applications, Data and AI, our services are relied
              on by customers in over 175 countries. Working together with our
              partners around the globe, we turn potential into reality.
            </p>
            <a href="#" className="button">
              View our services &rarr;
            </a>
          </div>
        </div>

        <h2 className=" selectedClient">Selected Clients Success Stories</h2>
        <div className="container">
          <div className="flex-container">
            <div className="card-134">
              <div className="card-content-1">
                <div className="tags">
                  <span className="tag">Hi-Tech</span>
                  <span className="tag">Artificial Intelligence</span>
                </div>
                <p className="description">
                  Devised an AI-powered platform that creates an organization's
                  custom ontologies and knowledge graphs. The Ontology optimizes
                  recommendations on a more connected basis making it easier to
                  target customers with the content most relevant to them.
                </p>
              </div>
              <img src={menImg} alt="Project 1" />
            </div>

            <div className="card-134">
              <img src={AiImg} alt="Project 2" />
              <div className="card-content-1">
                <div className="tags">
                  <span className="tag">Hi-Tech</span>
                  <span className="tag">Artificial Intelligence</span>
                </div>
                <p className="description">
                  Devised an AI-powered platform that creates an organization's
                  custom ontologies and knowledge graphs. The Ontology optimizes
                  recommendations on a more connected basis making it easier to
                  target customers with the content most relevant to them.
                </p>
              </div>
            </div>
          </div>

          <div className="row-container">
            <div className="card-colomn">
              <img src={infinityImg} alt="Project 3" />

              <div className="card-content-1">
                <div className="tags">
                  <span className="tag">Hi-Tech</span>
                  <span className="tag">Artificial Intelligence</span>
                </div>

                <p className="description">
                  Devised an AI-powered platform that creates an organization's
                  custom ontologies and knowledge graphs. The Ontology optimizes
                  recommendations on a more connected basis making it easier to
                  target customers with the content most relevant to them.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="width">
        <div className="meet">
          <h1 className="meet-heading">Meet the Kyndryls</h1>
          <p className="meet-para">
            Our experts work seamlessly with our customers' team to support big
            ambitions, challenge preconceptions, and amplify outcomes. Let's
            advance together.
          </p>
          <a href="#" className="button">
            View our services &rarr;
          </a>
        </div>
      </div>
    </section>
  );
};

export default TechnologyImpactSection;
