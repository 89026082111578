import React from "react";
import businessMenImg from "../../Images/smiling-young-businessman.jpg";
import "./Novamics.css";
import { useNavigate } from "react-router-dom";

function Novamics() {
  const navigate = useNavigate();
                
  const handleContactClick = () => {
    navigate("/contact");
  };
  return (
    <>
      <section className="seventh-sec">
        <div className="hero-section">
          <div className="alignment">
            <div className="hero-text">
              <h1>We’re Novamic</h1>
              <p>We #Buildforbillions</p>
            </div>
            <div className="info-box">
              <h2>Join the Leaders</h2>
              <p>
                Software, Systems, Applications & Products built by NeoSOFT are
                used by more than a billion people on earth. We work with the
                best brands across the globe, helping them #buildforbillions.
                Join the team and make a difference.
              </p>
              <a href="#" className="btn-primary">
                Explore Career Opportunities &rarr;
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="seventh-sec">
        <div className="alignment-1">
          <div className="content-section">
            <div className="image-container">
              <img src={businessMenImg} alt="Team" />
            </div>
            <div className="text-container">
              <h2>
                Our people.
                <br />
                Your progress.
              </h2>
              <p>
                Our people are our strength, at the center of everything we do.
                Working side-by-side with customers, they uncover root causes
                and co-create new, more intelligent solutions.
              </p>
              <a onClick={handleContactClick} className="btn-action">
                Book a consultation &rarr;
              </a>
            </div>
          </div>

          {/* Content Section 2 */}
          <div className="content-section-1">
            <div className="text-container">
              <a href="#" className="btn-action">
                Click &gt;&gt;
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Novamics;
