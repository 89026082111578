import React from "react";
import "./TeamSlider.css"; // Ensure styles are moved to this file
import img1 from "../../Images/businessWomen1.jpg";
import img2 from "../../Images/businessWomen2.jpg";
import img3 from "../../Images/businessLeader.jpg";

const TeamSlider = () => {
  const teamMembers = [
    {
      id: 1,
      name: "Nirav Mikus",
      position: "Vice President, Government",
      image: img1,
    },
    {
      id: 2,
      name: "Anita Mikus",
      position: "Vice President, Government",
      image: img2,
    },
    {
      id: 3,
      name: "Nirav Mikus",
      position: "Vice President, Government",
      image: img3,
    },
    {
      id: 4,
      name: "Nirav Mikus",
      position: "Vice President, Government",
      image: img1,
    },
  ];

  return (
    // <section className="cd-er">
    //   <div className="slider-wrapper">
    //     <div className="slider">
    //       <div className="col-md-12 heroSlider-fixed team_slider">
    //         <div className="overlay">
    //           <div className="slider responsive">
    //             {teamMembers.map((member) => (
    //               <div key={member.id} className="fact">
    //                 <img
    //                   className="card-image"
    //                   src={member.image}
    //                   alt={member.name}
    //                 />
    //                 <div className="flexy">
    //                   <h4 className="h-h">{member.name}</h4>
    //                   <p className="h-p">{member.position}</p>
    //                 </div>
    //               </div>
    //             ))}
    //           </div>
    //           <div className="prev">
    //             <span
    //               className="glyphicon glyphicon-menu-left"
    //               aria-hidden="true"
    //             ></span>
    //           </div>
    //           <div className="next">
    //             <span
    //               className="glyphicon glyphicon-menu-right"
    //               aria-hidden="true"
    //             ></span>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <div className="Container">
      <div className="grid">
        <div className="grid_card">
          <img src={img1} alt="Profile" />
          <div>
            <h3>Nirav Mikus</h3>
            <p>Vice President, Government</p>
          </div>
        </div>

        <div className="grid_card">
          <img src={img2} alt="Profile" />
          <div>
            <h3>Anita Mikus</h3>
            <p>Vice President, Government</p>
          </div>
        </div>

        <div className="grid_card">
          <img src={img3} alt="Profile" />
          <div>
            <h3>Nirav Mikus</h3>
            <p>Vice President, Government</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSlider;
