// eslint-disable-next-line
import React from "react";
import Header from "./Components/Header/Header";
import { Routes, Route, HashRouter } from "react-router-dom";
import About from "./Pages/About/About";
import Services from "./Pages/Services/Services";
import Careers from "./Pages/Careers/Careers";
import Footer from "./Components/Footer/Footer";
import Contact from "./Pages/Contact/Contact";
import Home from "./Pages/Home/Home";
import Portfolio from "./Pages/Portfolio/Portfolio";
import AiMl from "./Pages/AI-ML/Aiml";
import CloudComputing from "./Pages/CloudComputing/CloudComputing";
import DataEngineering from "./Pages/DataEngineering/DataEngineering";
import DevOps from "./Pages/DevOps/DevOps";
import DigitalTransformation from "./Pages/DigitalTransformation/DigitalTransformation";
import SoftwareandApplicationDeveopment from "./Pages/software&ApplicationDevelopment/Software&ApplicationDevelopment";
import TeamAugmentation from "./Pages/TeamAugmentation/TeamAugmentation";

function App() {
  return (
    <>
      <HashRouter>
        <Header></Header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/aiml" element={<AiMl />} />
          <Route path="/cloudComputing" element={<CloudComputing />} />
          <Route path="/dataEngineering" element={<DataEngineering />} />
          <Route path="/devOps" element={<DevOps />} />
          <Route
            path="/digitalTransformation"
            element={<DigitalTransformation />}
          />
          <Route
            path="/softwareandApplicationDevelopment"
            element={<SoftwareandApplicationDeveopment />}
          />
          <Route path="/teamAugmentation" element={<TeamAugmentation />} />
        </Routes>
        {/* <Footer></Footer> */}
      </HashRouter>
    </>
  );
}

export default App;
