import React from "react";
import "./Portfolio.css";
import businessmanIMg from "../../Images/smiling-young-businessman.jpg";
import logoImg from "../../Images/Logo File.png";
import { useNavigate } from "react-router-dom";

function Portfolio() {
  const navigate = useNavigate();
                
  const handleContactClick = () => {
    navigate("/contact");
  };
  return (
    <>
      <section className="fir-sec">
        <div className="path">
          <div className="main-path">
            <span className="design-1">Home &gt; Services &gt; Portfolio</span>
            <span className="design-2">Amazon D3Ploy</span>
          </div>
        </div>
      </section>

      <section className="sen-sec">
        <div className="alingment">
          <div className="qa-section">
            <div className="qa-text">Amazon D3Ploy</div>
          </div>
        </div>
      </section>

      <section className="thi-sec">
        <div className="bac-img"></div>
      </section>

      <section className="fourth-sec grey">
        <div className="ali grey">
          <div className="center-121">
            <p className="text-22">
              Novamic Technologies played a pivotal role in the comprehensive
              development and successful implementation of Amazon D3Ploy on the
              Amazon platform. This platform serves as Amazon's efficient and
              budget-friendly vulnerability report system, accommodating diverse
              financial considerations. Our dedicated team actively contributed
              to shaping and refining the various features of Amazon D3Ploy,
              including the creation of a Dashboard, Projects, Document Library,
              Composite View, Access Map, Module Tracker, Milestone Review,
              Financial Management, and Financial Management Reports.
            </p>
            <p className="text-22">
              Throughout the design, development, and deployment phases, our
              focus remained on optimizing performance, enhancing reliability,
              and elevating the user experience across Amazon D3Ploy's
              functionalities.
            </p>
            <p className="text-22">
              By leveraging our technical expertise, we aimed to build a robust
              and efficient system that seamlessly aligns with the dynamic
              requirements set by Amazon for its deployment services. The result
              is a sophisticated and reliable platform that adheres to the high
              standards set by Amazon for deployment solutions.
            </p>
          </div>
        </div>
      </section>

      <section className="seventh-sec">
        <div className="hero-section">
          <div className="alingment-889">
            <div className="hero-text">
              <h1>We’re Novamic</h1>
              <p>We #Buildforbillions</p>
            </div>
            <div className="info-box">
              <h2>Join the Leaders</h2>
              <p>
                Software, Systems, Applications & Products built by NeoSOFT are
                used by more than a billion people on earth. We work with the
                best brands across the globe, helping them #buildforbillions.
                Join the team and make a difference.
              </p>
              <a href="#" className="btn-primary">
                Explore Career Opportunities &rarr;
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="eighth-sec">
        <div className="ali">
          <div className="responsive-container-19">
            <div className="responsive-image">
              <img src={businessmanIMg} alt="Description of the image" />
            </div>

            <div className="responsive-content">
              <h2 className="kok">
                Get a 30-minute, no-cost strategy session with an Application
                services expert
              </h2>
              <a onClick={handleContactClick} className="content-button">
                Book a consultation &rarr;
              </a>
            </div>
          </div>

          <div className="content-section-1">
            <div className="text-container">
              <a href="#" className="content-button">
                Click &gt;&gt;
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-sec">
        <div className="alingment-8588">
          <footer className="footer">
            <div className="footer-logo-section">
              <div className="footer-logo">
                <img src={logoImg} alt="Novamic Logo" />
              </div>
              <p className="footer-description">
                Novamic Technologies was established in order to develop
                creative digital platforms of technology and information.
              </p>
            </div>

            <div className="footer-links-section">
              <h4>Quick Links</h4>
              <ul className="jojo">
                <li>
                  <a href="#about">About us</a>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
                <li>
                  <a href="#career">Career</a>
                </li>
              </ul>
            </div>

            <div className="footer-newsletter-section">
              <h4>News Letter</h4>
              <p className="footer-newsletter-text">
                Don’t miss to subscribe to our new feeds, kindly fill the form
                below:
              </p>
              <form className="newsletter-form">
                <input
                  type="email"
                  className="newsletter-input"
                  placeholder="Email Address"
                  required
                />
                <button type="submit" className="newsletter-button">
                  →
                </button>
              </form>
              <div className="footer-social-links">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  LinkedIn
                </a>
              </div>
            </div>
          </footer>
        </div>
      </section>
    </>
  );
}

export default Portfolio;
