import React, { useState, useEffect } from "react";
import HomeImg from "../../Images/Group 9.png";
import "./Home.css";
import StatsSection from "../../Components/StatsSection/StatsSection";
import WhatWeDoSection from "../../Components/WhatWeDo/WhatWeDoSection";
import TechnologyImpactSection from "../../Components/TechnologyImpactSection/TechnologyImpactSection";
// import Portfolio from '../../Components/'
import TeamSlider from "../../Components/TeamSlider/TeamSlider";
import Novamics from "../../Components/Novamics/Novamics";
import logoImg from "../../Images/Logo File.png";
import AiImg from "../../Images/aiTechnologyImg.jpg";
import menImg from "../../Images/menGlassesImg.jpeg";
import infinityImg from "../../Images/infinityImg.jpeg";
import videoPauseImg from "../../Images/videoPauseImg.png";
import businessMenImg from "../../Images/smiling-young-businessman.jpg";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
                
  const handleContactClick = () => {
    navigate("/contact");
  };
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    {
      heading: "Driving Digital Growth",
      description:
        "Fueling businesses with sustained digital capabilities, resilient products, and next-gen solutions.",
    },
    {
      heading: "Driving Digital Growth",
      description:
        "Fueling businesses with sustained digital capabilities, resilient products, and next-gen solutions.",
    },
    {
      heading: "Driving Digital Growth",
      description:
        "Fueling businesses with sustained digital capabilities, resilient products, and next-gen solutions.",
    },
  ];

  const showSlide = (index) => {
    const totalSlides = slides.length;

    if (index >= totalSlides) setCurrentIndex(0);
    else if (index < 0) setCurrentIndex(totalSlides - 1);
    else setCurrentIndex(index);
  };

  const updateNavigation = () => {
    // This handles navigation updates if required dynamically
  };

  const currentSlide = (index) => {
    showSlide(index - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      showSlide(currentIndex + 1);
    }, 5000);

    return () => clearInterval(interval); // Cleanup
  }, [currentIndex]);

  return (
    <>
      <div className="slider">
        <div
          className="slides"
          style={{ transform: `translateX(${-currentIndex * 100}%)` }}
        >
          {slides.map((slide, index) => (
            <div className="slide" key={index}>
              <img
                className="slider-image"
                src={HomeImg}
                alt={`Slide ${index + 1}`}
              />
              <div className="text-overlay">
                <h2 className="slider-heading">{slide.heading}</h2>
                <p className="slider-para">{slide.description}</p>
                <button onClick={handleContactClick} className="slider-btn">Let’s Talk</button>
              </div>
            </div>
          ))}
        </div>

        <div className="navigation" style={{ zIndex: 25 }}>
          {slides.map((_, index) => (
            <span
              key={index}
              className={`nav-dot ${index === currentIndex ? "active" : ""}`}
              onClick={() => currentSlide(index + 1)}
            ></span>
          ))}
        </div>
      </div>

      <section className="second-sec">
        <div className="container">
          <div className="left-text">
            <h2 className="second-section-heading">
              We create solutions for
              <br />
              Millions that contributes to
              <br />
              social progress
            </h2>
          </div>
          <div className="right-text">
            <p className="second-section-para">
              Novamic Technologies is a reliable partner in IT Outsourcing
              solutions across Banking, Real Estate, Healthcare, Travel and
              hospitality industry. Our aim is simple: to provide unparalleled
              value by combining innovative solutions, with technical expertise
              and operational excellence.
            </p>
          </div>
        </div>
      </section>

      <StatsSection />
      <WhatWeDoSection />
      <TechnologyImpactSection />
      <TeamSlider />

      <section className="seventh-sec">
        <div className="hero-section">
          <div className="hero-section_alignment">
            <div className="hero_text">
              <h1>We’re Novamic</h1>
              <p>We #Buildforbillions</p>
            </div>
            <div className="info-box">
              <h2>Join the Leaders</h2>
              <p>
                Software, Systems, Applications & Products built by NeoSOFT are
                used by more than a billion people on earth. We work with the
                best brands across the globe, helping them #buildforbillions.
                Join the team and make a difference.
              </p>
              <a href="#" className="btn-primary">
                Explore Career Opportunities &rarr;
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="seventh-sec">
        <div className="alignment-1">
          <div className="content-section">
            <div className="image-container">
              <img src={businessMenImg} alt="Team" />
            </div>
            <div className="text-container">
              <h2>
                Our people.
                <br />
                Your progress.
              </h2>
              <p>
                Our people are our strength, at the center of everything we do.
                Working side-by-side with customers, they uncover root causes
                and co-create new, more intelligent solutions.
              </p>
              <a onClick={handleContactClick} className="btn-action">
                Book a consultation &rarr;
              </a>
            </div>
          </div>

          <div className="content-section-1">
            <div className="text-container_click">
              <a href="#" className="btn-action">
                Click &gt;&gt;
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-sec">
        <div className="alingment">
          <footer className="footer">
            <div className="footer-logo-section">
              <div className="footer-logo">
                <img src={logoImg} alt="Novamic Logo" />
              </div>
              <p className="footer-description">
                Novamic Technologies was established in order to develop
                creative digital platforms of technology and information.
              </p>
            </div>

            <div className="footer-links-section">
              <h4>Quick Links</h4>
              <ul className="jojo">
                <li>
                  <a href="#about">About us</a>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
                <li>
                  <a href="#career">Career</a>
                </li>
              </ul>
            </div>

            <div className="footer-newsletter-section">
              <h4>News Letter</h4>
              <p className="footer-newsletter-text">
                Don’t miss to subscribe to our new feeds, kindly fill the form
                below:
              </p>
              <form className="newsletter-form">
                <input
                  type="email"
                  className="newsletter-input"
                  placeholder="Email Address"
                  required
                />
                <button type="submit" className="newsletter-button">
                  →
                </button>
              </form>
              <div className="footer-social-links">
                <a href="#" target="_blank">
                  LinkedIn
                </a>
              </div>
            </div>
          </footer>
        </div>
      </section>
    </>
  );
};

export default Home;
