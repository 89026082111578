import React from "react";
import "./Services.css";
import costumerImg from "../../Images/costumer_6012670.png";
import logoImage from "../../Images/Logo.png";
import img1 from "../../Images/01.jpg";
import img2 from "../../Images/02.jpg";
import img3 from "../../Images/03.jpg";
import img4 from "../../Images/04.jpg";
import img5 from "../../Images/05.jpg";
import googleImg from "../../Images/google_cloud_4x3_2x1_Small.jfif";
import awsImg from "../../Images/aws_4x3_2x1_Large.jfif";
import microsoftImg from "../../Images/Microsoft_4x3_2x1_Small.jfif";
import peopleIMg from "../../Images/full-shot-people-office.jpg";
import { useNavigate } from "react-router-dom";

const Service = () => {
   const navigate = useNavigate();
    
    const handleContactClick = () => {
      navigate("/contact");
    };
  return (
    <div>
      {/* Breadcrumb Section */}
      <section className="fir-sec">
        <div className="path">
          <div className="main-path">
            <span className="design-1">Home &gt; Services &gt;</span>
            <span className="design-2">Quality Assurance</span>
          </div>
        </div>
      </section>

      {/* Quality Assurance Section */}
      <section className="sen-sec">
        <div className="aligngment">
          <div className="qa-section">
            <div className="qa-text">Quality Assurance</div>
          </div>
        </div>
      </section>

      {/* QA Content Section */}
      <section className="thi-sec">
        <div className="ali">
          <div className="qa-content">
            <div className="qa-left">
              <p>
                At Novamic Technologies, we specialize in software testing
                services dedicated to ensuring top-notch product quality. Our
                commitment to practices like early and frequent testing has
                enabled us to effectively meet your software testing
                requirements.
              </p>
            </div>
            <div className="qa-right">
              <p>
                Our team of expert quality assurance engineers excels in testing
                comprehensive workflows and executing detailed test case
                scenarios. Additionally, our focused teams perform independent
                testing of your applications using advanced techniques and
                cutting-edge technology.
              </p>
              <p>
                By employing premier software testing methodologies, we enhance
                overall time-to-market and product quality. Our goal is to
                achieve extensive test coverage and precise deliverables. Our
                underlying idea lies in creating testing guidelines that promote
                cost efficiency, optimal use of QA resources, and minimized risk
                of failure. Consequently, we ensure customer satisfaction by
                prioritizing thorough testing processes over merely delivering
                tested products.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="fourth-sect">
        <div className="">
          <div className="features-section">
            <div className="feature-container ali">
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 1 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 2 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 3 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Responsive Content Section */}
      <section className="fifth-sect">
        <div className="ali">
          <h1 className="hea">Quality Assurance</h1>
          <div clasNames="responsive-section">
            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img src={img1} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">Automation Testing</h2>
                <p className="content-text">
                  Our forte lies in Automation Testing, which involves using
                  automated tools and scripts to execute test cases and verify
                  software functionalities. Automation testing helps in
                  accelerating the testing process, improving test coverage, and
                  ensuring consistency in testing results. This further enables
                  clients to face minimal downtime, and bug-free code,
                  consequently leading to successful business growth.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container">
              <div className="responsive-image">
                <img src={img2} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">Automation Testing</h2>
                <p className="content-text">
                  Our forte lies in Automation Testing, which involves using
                  automated tools and scripts to execute test cases and verify
                  software functionalities. Automation testing helps in
                  accelerating the testing process, improving test coverage, and
                  ensuring consistency in testing results. This further enables
                  clients to face minimal downtime, and bug-free code,
                  consequently leading to successful business growth.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img src={img3} alt="Description of the image" />
              </div>

              <div className="responsive-content">
                <h2 className="content-heading">Automation Testing</h2>
                <p className="content-text">
                  Our forte lies in Automation Testing, which involves using
                  automated tools and scripts to execute test cases and verify
                  software functionalities. Automation testing helps in
                  accelerating the testing process, improving test coverage, and
                  ensuring consistency in testing results. This further enables
                  clients to face minimal downtime, and bug-free code,
                  consequently leading to successful business growth.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container">
              <div className="responsive-image">
                <img src={img4} alt="Description of the image" />
              </div>

              <div className="responsive-content  ">
                <h2 className="content-heading">Automation Testing</h2>
                <p className="content-text">
                  Our forte lies in Automation Testing, which involves using
                  automated tools and scripts to execute test cases and verify
                  software functionalities. Automation testing helps in
                  accelerating the testing process, improving test coverage, and
                  ensuring consistency in testing results. This further enables
                  clients to face minimal downtime, and bug-free code,
                  consequently leading to successful business growth.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img src={img5} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">Automation Testing</h2>
                <p className="content-text">
                  Our forte lies in Automation Testing, which involves using
                  automated tools and scripts to execute test cases and verify
                  software functionalities. Automation testing helps in
                  accelerating the testing process, improving test coverage, and
                  ensuring consistency in testing results. This further enables
                  clients to face minimal downtime, and bug-free code,
                  consequently leading to successful business growth.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sixth-sect">
        <div className="ali pop">
          <div className="responsive-content-1">
            <span className="quotes">"</span>
            <p className="content-text-1">
              Working with Kyndryl to move to SAP S/4HANA was one of the
              smoothest projects I've ever been involved with. Even though we
              were contending with the impacts of COVID-19 throughout the
              engagement, Kyndryl mobilized its full global resources to help us
              stay on track and go live on time.
            </p>
            <span className="quotes">"</span>
          </div>
          <h1 className="hed-12">Rainer Steffl</h1>
          <p className="para-12">CIO, Mondi Group</p>
        </div>
      </section>

      <section className="seventh-sect">
        <div className="ali flexi">
          <div className="partnerships-text">
            <h2>Partnerships</h2>
            <p>Learn more about some of our key partnerships</p>
          </div>
          <div className="partnerships-logos">
            <img src={googleImg} alt="Google Cloud" />
            <img src={microsoftImg} alt="Microsoft" />
            <img src={awsImg} alt="AWS" />
          </div>
        </div>
      </section>

      <section className="Eighth-sec">
        <div className="ali">
          <div className="responsive-container-19">
            <div className="responsive-image">
              <img src={peopleIMg} alt="Description of the image" />
            </div>

            <div className="responsive-content ">
              <h2 className="kok">
                Get a 30-minute, no-cost strategy session with an Application
                services expert
              </h2>

              <a onClick={handleContactClick} className="content-button">
                Let's Talk &rarr;
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-sec">
        <div className="alingment-8588">
          <footer className="footer">
            <div className="footer-logo-section">
              <div className="footer-logo">
                <img src={logoImage} alt="Novamic Logo" />
              </div>
              <p className="footer-description">
                Novamic Technologies was established in order to develop
                creative digital platforms of technology and information.
              </p>
            </div>

            <div className="footer-links-section">
              <h4>Quick Links</h4>
              <ul className="jojo">
                <li>
                  <a href="#about">About us</a>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
                <li>
                  <a href="#career">Career</a>
                </li>
              </ul>
            </div>

            <div className="footer-newsletter-section">
              <h4>News Letter</h4>
              <p className="footer-newsletter-text">
                Don’t miss to subscribe to our new feeds, kindly fill the form
                below:
              </p>
              <form className="newsletter-form">
                <input
                  type="email"
                  className="newsletter-input"
                  placeholder="Email Address"
                  required
                />
                <button type="submit" className="newsletter-button">
                  →
                </button>
              </form>
              <div className="footer-social-links">
                <a href="#" target="_blank">
                  LinkedIn
                </a>
              </div>
            </div>
          </footer>
        </div>
      </section>
    </div>
  );
};

export default Service;
