import React from "react";
import "./DevOps.css";
import costumerImg from "../../Images/costumer_6012670.png";
import logoImage from "../../Images/Logo.png";
import img1 from "../../Images/01.jpg";
import bigData from "../../Images/Big Data.png";
import img2 from "../../Images/02.jpg";
import img3 from "../../Images/03.jpg";
import devOpsConsulting from "../../Images/DevOps Consulting.png";
import devOpsTransformation from "../../Images/DevOps Transformation.png";
import dataAnalytics from "../../Images/Data Analytics.png";
import businessIntelligence from "../../Images/Business Intelligence (2).png";
import img4 from "../../Images/04.jpg";
import dataWarehouse from "../../Images/Data Warehouse and ETL.png";
import img5 from "../../Images/05.jpg";
import googleImg from "../../Images/google_cloud_4x3_2x1_Small.jfif";
import awsImg from "../../Images/aws_4x3_2x1_Large.jfif";
import microsoftImg from "../../Images/Microsoft_4x3_2x1_Small.jfif";
import peopleIMg from "../../Images/full-shot-people-office.jpg";
import { useNavigate } from "react-router-dom";

const DevOps = () => {
   const navigate = useNavigate();
    
    const handleContactClick = () => {
      navigate("/contact");
    };
  return (
    <div>
      {/* Breadcrumb Section */}
      <section className="fir-sec">
        <div className="path">
          <div className="main-path">
            <span className="design-1">Home &gt; Services &gt;</span>
            <span className="design-2">DevOps</span>
          </div>
        </div>
      </section>

      {/* Quality Assurance Section */}
      <section className="sen-sec">
        <div className="aligngment">
          <div className="qa-section">
            <div className="qa-text">DevOps</div>
          </div>
        </div>
      </section>

      {/* QA Content Section */}
      <section className="thi-sec">
        <div className="ali">
          <div className="qa-content">
            <div className="qa-left">
              <p>
                DevOps, being a core practice vertical at Novamic Technolgies,
                helps businesses in strategically implement test automation,
                enhance code integrity, and mitigate deployment risks through
                DevOps methodologies. Furthermore, our services aid in aligning
                development strategies with business objectives, enabling
                accelerated innovation through shorter release cycles for a
                resilient ecosystem and superior software quality.
              </p>
            </div>
            <div className="qa-right">
              <p>
                DevOps, being a core practice vertical at Novamic Technolgies,
                helps businesses in strategically implement test automation,
                enhance code integrity, and mitigate deployment risks through
                DevOps methodologies. Furthermore, our services aid in aligning
                development strategies with business objectives, enabling
                accelerated innovation through shorter release cycles for a
                resilient ecosystem and superior software quality.
              </p>
              <p></p>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="fourth-sect">
        <div className="">
          <div className="features-section">
            <div className="feature-container ali">
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 1 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 2 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 3 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Responsive Content Section */}
      <section className="fifth-sect">
        <div className="ali">
          <h1 className="hea">DevOps</h1>
          <div clasNames="responsive-section">
            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img src={devOpsConsulting} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">DevOps Consulting</h2>
                <p className="content-text">
                  Through our DevOps consulting, we help businesses with
                  efficient IT operational management, ensuring seamless
                  communication and heightened efficiency. Our services include
                  outsourcing IT operations to specialized third-party
                  providers, allowing businesses to focus on core competencies
                  while benefiting from expert management of IT aspects. This
                  approach enhances operational efficiency by leveraging
                  advanced technologies and best practices to optimize business
                  processes.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container">
              <div className="responsive-image">
                <img
                  src={devOpsTransformation}
                  alt="Description of the image"
                />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">DevOps Transformation</h2>
                <p className="content-text">
                  We craft optimal DevOps implementation strategies to
                  streamline workflows, automate CI/CD pipelines, expedite
                  software delivery, and uphold development standards. Our
                  DevOps practices aim to deliver value through seamless
                  integration and efficiency. Our services further enable our
                  clientele to automate processes, strengthen app security and
                  enforce innovative solutions.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sixth-sect">
        <div className="ali pop">
          <div className="responsive-content-1">
            <span className="quotes">"</span>
            <p className="content-text-1">
              Working with Kyndryl to move to SAP S/4HANA was one of the
              smoothest projects I've ever been involved with. Even though we
              were contending with the impacts of COVID-19 throughout the
              engagement, Kyndryl mobilized its full global resources to help us
              stay on track and go live on time.
            </p>
            <span className="quotes">"</span>
          </div>
          <h1 className="hed-12">Rainer Steffl</h1>
          <p className="para-12">CIO, Mondi Group</p>
        </div>
      </section>

      <section className="seventh-sect">
        <div className="ali flexi">
          <div className="partnerships-text">
            <h2>Partnerships</h2>
            <p>Learn more about some of our key partnerships</p>
          </div>
          <div className="partnerships-logos">
            <img src={googleImg} alt="Google Cloud" />
            <img src={microsoftImg} alt="Microsoft" />
            <img src={awsImg} alt="AWS" />
          </div>
        </div>
      </section>

      <section className="Eighth-sec">
        <div className="ali">
          <div className="responsive-container-19">
            <div className="responsive-image">
              <img src={peopleIMg} alt="Description of the image" />
            </div>

            <div className="responsive-content ">
              <h2 className="kok">
                Get a 30-minute, no-cost strategy session with an Application
                services expert
              </h2>

              <a onClick={handleContactClick} className="content-button">
                Let's Talk &rarr;
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-sec">
        <div className="alingment-8588">
          <footer className="footer">
            <div className="footer-logo-section">
              <div className="footer-logo">
                <img src={logoImage} alt="Novamic Logo" />
              </div>
              <p className="footer-description">
                Novamic Technologies was established in order to develop
                creative digital platforms of technology and information.
              </p>
            </div>

            <div className="footer-links-section">
              <h4>Quick Links</h4>
              <ul className="jojo">
                <li>
                  <a href="#about">About us</a>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
                <li>
                  <a href="#career">Career</a>
                </li>
              </ul>
            </div>

            <div className="footer-newsletter-section">
              <h4>News Letter</h4>
              <p className="footer-newsletter-text">
                Don’t miss to subscribe to our new feeds, kindly fill the form
                below:
              </p>
              <form className="newsletter-form">
                <input
                  type="email"
                  className="newsletter-input"
                  placeholder="Email Address"
                  required
                />
                <button type="submit" className="newsletter-button">
                  →
                </button>
              </form>
              <div className="footer-social-links">
                <a href="#" target="_blank">
                  LinkedIn
                </a>
              </div>
            </div>
          </footer>
        </div>
      </section>
    </div>
  );
};

export default DevOps;
