import React from "react";
import img1 from "../../Images/two-businessman-shaking-hands-social-network-hologram-double-exposure-concept-international-people-connection-handshake.jpg";
import img2 from "../../Images/ux-graphic-designer-planning-application-process-development-prototype-wireframe-web-smart-phone-creative-digital-development-agencyxa.jpg";
import img3 from "../../Images/ux-designer-working-interface.jpg";
import "./WhatWeDoSection.css"; // Add necessary styles in this file

const WhatWeDoSection = () => {
  return (
    <>
      <section className="4th-sec">
        <div className="what-we-do">
          <h2 className="card-top">What We Do</h2>
          <div className="card-container">
            <div className="card">
              <img src={img1} alt="Team Augmentation" />
              <div className="card-content">
                <h3 className="fourth-sec-head">Team Augmentation</h3>
                <p className="fourth-sec-para">
                  Our dedicated Quality Software Engineers will assist you in
                  the development & maintenance of your software or product.
                  This will benefit in cost saving, specialized skills, faster
                  project execution, and learning opportunities.
                </p>
              </div>
            </div>
            <div className="card">
              <img src={img2} alt="Enterprise Digital Transformation" />
              <div className="card-content">
                <h3 className="fourth-sec-head">
                  Enterprise Digital Transformation
                </h3>
                <p className="fourth-sec-para">
                  Our Enterprise Application capabilities are modeled to help
                  businesses unlock value leveraging digital. From consulting,
                  optimization to automation, we leverage popular tools,
                  products, and generative AI to help enterprises thrive in the
                  modern digital ecosystem.
                </p>
              </div>
            </div>
            <div className="card">
              <img src={img3} alt="End to End Product Development" />
              <div className="card-content">
                <h3 className="fourth-sec-head">
                  End to End Product Development
                </h3>
                <p className="fourth-sec-para">
                  We engineer robust, scalable, & intuitive products that are
                  built for all. We ensure products crafted by our teams deliver
                  phenomenal stakeholder value.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fifth-sec">
        <div className="background-section">
          <div className="content"></div>
        </div>
      </section>
    </>
  );
};

export default WhatWeDoSection;
