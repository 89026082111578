import React from "react";
import "./StatsSection.css"; // Add necessary styles in this file

const StatsSection = () => {
  return (
    <section className="stats-section">
      <p className="stats-intro">Let us show you some stats</p>
      <h2 className="stats-title">
        We have done great stuff over
        <br />
        the past few years.
      </h2>
      <div className="stats-container">
        <div className="stat">
          <div className="icon">⭐</div>
          <h3 className="counter">25+</h3>
          <p className="text">Happy Clients</p>
        </div>
        <div className="stat">
          <div className="icon">📄</div>
          <h3 className="counter">3+</h3>
          <p className="text">Years of Experience</p>
        </div>
        <div className="stat">
          <div className="icon">✅</div>
          <h3 className="counter">100+</h3>
          <p className="text">Projects Completed</p>
        </div>
      </div>
    </section>
  );
};

export default StatsSection;
