import React from "react";
import "./softwareAndApplicationDevelopment.css";
import costumerImg from "../../Images/costumer_6012670.png";
import logoImage from "../../Images/Logo.png";
import img1 from "../../Images/01.jpg";
import img2 from "../../Images/02.jpg";
import img3 from "../../Images/03.jpg";
import websiteDev from "../../Images/Website Development.png";
import appdev from "../../Images/App Development.png";
import ecommerceDevelopment from "../../Images/E-commerce Development.png";
import portalDev from "../../Images/Portal Development.png";
import productDev from "../../Images/Product Development.png";
import img4 from "../../Images/04.jpg";
import img5 from "../../Images/05.jpg";
import googleImg from "../../Images/google_cloud_4x3_2x1_Small.jfif";
import awsImg from "../../Images/aws_4x3_2x1_Large.jfif";
import microsoftImg from "../../Images/Microsoft_4x3_2x1_Small.jfif";
import peopleIMg from "../../Images/full-shot-people-office.jpg";
import { useNavigate } from "react-router-dom";

const SoftwareandApplicationDeveopment = () => {
   const navigate = useNavigate();
    
    const handleContactClick = () => {
      navigate("/contact");
    };
  return (
    <div>
      {/* Breadcrumb Section */}
      <section className="fir-sec">
        <div className="path">
          <div className="main-path">
            <span className="design-1">Home &gt; Services &gt;</span>
            <span className="design-2">
              Software and Application Development
            </span>
          </div>
        </div>
      </section>

      {/* Quality Assurance Section */}
      <section className="sen-sec">
        <div className="aligngment">
          <div className="qa-section">
            <div className="qa-text">Software and Application Development</div>
          </div>
        </div>
      </section>

      {/* QA Content Section */}
      <section className="thi-sec">
        <div className="ali">
          <div className="qa-content">
            <div className="qa-left">
              <p>
                We are adept with the best technologies in the market and use a
                customer-focused approach and have a vast domain experience with
                teams who bring along top-notch innovation and technology
                competence.
              </p>
            </div>
            <div className="qa-right">
              <p>
                We provide comprehensive solutions including Enterprise Process
                Management (ERPs), Customer Relationship Management Software
                (CRMs), Human Resources Management Software (HRMS), Content
                Management Systems (CMS), and Asset Management Software.
              </p>
              <p>
                These tools are designed to streamline operations, enhance
                customer interactions, optimize workforce management, facilitate
                content organization, and effectively manage assets across
                various organizational functions.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="fourth-sect">
        <div className="">
          <div className="features-section">
            <div className="feature-container ali">
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 1 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 2 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 3 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Responsive Content Section */}
      <section className="fifth-sect">
        <div className="ali">
          <h1 className="hea">Software and Application Development</h1>
          <div clasNames="responsive-section">
            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img src={websiteDev} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">Website Development</h2>
                <p className="content-text">
                  If you are looking for the right internet-marketing experts
                  who can build your website or if you wish to revamp your
                  website, Novamic Technologies is the go-to place for you. Our
                  expert team possesses great knowledge of website designing and
                  website development for your website to stand out on the web.
                  Our web developers bring with themselves wealth of experience,
                  quality, innovation, creativity and also comply with website
                  standards. We have provided our varied services to top clients
                  and individuals from a diverse spectrum of business. We
                  understand the changing needs with time and excel at creating
                  websites than enhance your brand presence.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container">
              <div className="responsive-image">
                <img src={appdev} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">App Development</h2>
                <p className="content-text">
                  Our application development process goes through many phases
                  to deliver the best mobile application to you. We assure
                  quality guarantee, safety, testing, integration and maximum
                  content management. While crafting apps for you, our
                  developers understand your idea and execute it and deliver the
                  resultant mobile apps for androids, iOS with highly robust
                  strategies. Our customized mobile apps are assured of maximum
                  agility and predictability. With strong custom development
                  strategy and keeping all aspects like integration, quality
                  guarantee, safety, testing and uninterrupted services in mind,
                  we design and develop agile and user-friendly apps for
                  businesses of all sizes.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img
                  src={ecommerceDevelopment}
                  alt="Description of the image"
                />
              </div>

              <div className="responsive-content">
                <h2 className="content-heading">E-commerce Development</h2>
                <p className="content-text">
                  With the world transforming into a global village, companies
                  have their customized ecommerce websites that help them
                  generate traffic and increase sales thereby leading to more
                  results. We understand the industry; you place in the industry
                  and develop solutions that are implemented, customized and
                  integrated especially for your needs. Our service like
                  Integrated Marketing campaigns, Clarity & Consistency,
                  Shopping Cart Development, Responsive e-commerce web apps,
                  secure payment gateways have helped our clients rely on
                  websites that are easy to use, convenient and help businesses
                  accelerate their revenue graph.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container">
              <div className="responsive-image">
                <img src={portalDev} alt="Description of the image" />
              </div>

              <div className="responsive-content  ">
                <h2 className="content-heading">Portal Development</h2>
                <p className="content-text">
                  At Novamic Technologies, we specialize in bespoke portal
                  development solutions tailored for a wide array of industries,
                  including travel, hospitality, healthcare, manufacturing, and
                  pharmaceuticals. Leveraging cutting-edge IT methodologies such
                  as Agile, DevOps, and SCRUM, we meticulously design and deploy
                  portals that cater to the specific operational needs and
                  digital transformation goals of businesses, irrespective of
                  their scale. Our approach integrates advanced functionalities
                  like seamless integration with existing IT ecosystems, robust
                  data security measures, and intuitive user interfaces,
                  ensuring optimized performance and enhanced user experiences.
                  Whether streamlining booking processes, enhancing patient
                  management systems, or optimizing supply chain operations, our
                  expertise in portal development enables businesses to achieve
                  accelerated growth and operational excellence in today's
                  competitive market environment. <br />
                  Services:-
                  <ul class="content-text">
                    <li>• Web & Mobile Applications</li>
                    <li>• Customised Platforms</li>
                    <li>• Virtual Assistance</li>
                    <li>• Yield Management</li>
                    <li>• Channel Management</li>
                    <li>• Social Media Management</li>
                  </ul>
                  <p class="content-text">Expertise:-</p>
                  <ul class="content-text">
                    <li>• Internet of Things (IoT)</li>
                    <li>• Augmented Reality</li>
                    <li>• Big Data & Analytics</li>
                    <li>• Blockchain</li>
                    <li>• APIs and Analytics</li>
                    <li>• Payment Integration</li>
                    <li>• Chatbots</li>
                    <li>• Business Intelligence</li>
                    <li>• CRM, ERP Systems</li>
                    <li>• Cloud Development</li>
                    <li>• IT Integrity Management</li>
                  </ul>
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img src={productDev} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">Product Development</h2>
                <p className="content-text">
                  At Novamic Technologies, we specialize in transforming
                  concepts into reality through comprehensive software
                  development solutions. Our approach integrates advanced
                  methodologies such as DevOps, Waterfall, Prototyping, SCRUM,
                  and Agile, ensuring agile and efficient product development.
                  Emphasizing a human-centric design philosophy, our diverse
                  teams oversee the entire lifecycle of digital product
                  development. Our meticulous Product Development process begins
                  with market analysis and ideation, followed by rigorous
                  feasibility studies and meticulous testing, culminating in a
                  seamless product launch. We prioritize market dynamics to
                  deliver precisely tailored solutions. Our commitment to
                  enhancing time-to-market and profitability underscores our
                  dedication to delivering end-to-end product development
                  excellence, consistently validated by our satisfied clientele.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sixth-sect">
        <div className="ali pop">
          <div className="responsive-content-1">
            <span className="quotes">"</span>
            <p className="content-text-1">
              Working with Kyndryl to move to SAP S/4HANA was one of the
              smoothest projects I've ever been involved with. Even though we
              were contending with the impacts of COVID-19 throughout the
              engagement, Kyndryl mobilized its full global resources to help us
              stay on track and go live on time.
            </p>
            <span className="quotes">"</span>
          </div>
          <h1 className="hed-12">Rainer Steffl</h1>
          <p className="para-12">CIO, Mondi Group</p>
        </div>
      </section>

      <section className="seventh-sect">
        <div className="ali flexi">
          <div className="partnerships-text">
            <h2>Partnerships</h2>
            <p>Learn more about some of our key partnerships</p>
          </div>
          <div className="partnerships-logos">
            <img src={googleImg} alt="Google Cloud" />
            <img src={microsoftImg} alt="Microsoft" />
            <img src={awsImg} alt="AWS" />
          </div>
        </div>
      </section>

      <section className="Eighth-sec">
        <div className="ali">
          <div className="responsive-container-19">
            <div className="responsive-image">
              <img src={peopleIMg} alt="Description of the image" />
            </div>

            <div className="responsive-content ">
              <h2 className="kok">
                Get a 30-minute, no-cost strategy session with an Application
                services expert
              </h2>

              <a onClick={handleContactClick} className="content-button">
                Let's Talk &rarr;
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-sec">
        <div className="alingment-8588">
          <footer className="footer">
            <div className="footer-logo-section">
              <div className="footer-logo">
                <img src={logoImage} alt="Novamic Logo" />
              </div>
              <p className="footer-description">
                Novamic Technologies was established in order to develop
                creative digital platforms of technology and information.
              </p>
            </div>

            <div className="footer-links-section">
              <h4>Quick Links</h4>
              <ul className="jojo">
                <li>
                  <a href="#about">About us</a>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
                <li>
                  <a href="#career">Career</a>
                </li>
              </ul>
            </div>

            <div className="footer-newsletter-section">
              <h4>News Letter</h4>
              <p className="footer-newsletter-text">
                Don’t miss to subscribe to our new feeds, kindly fill the form
                below:
              </p>
              <form className="newsletter-form">
                <input
                  type="email"
                  className="newsletter-input"
                  placeholder="Email Address"
                  required
                />
                <button type="submit" className="newsletter-button">
                  →
                </button>
              </form>
              <div className="footer-social-links">
                <a href="#" target="_blank">
                  LinkedIn
                </a>
              </div>
            </div>
          </footer>
        </div>
      </section>
    </div>
  );
};

export default SoftwareandApplicationDeveopment;
