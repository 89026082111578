import React from "react";
import "./TeamAugmentation.css";
import costumerImg from "../../Images/costumer_6012670.png";
import logoImage from "../../Images/Logo.png";
import img1 from "../../Images/01.jpg";
import img2 from "../../Images/02.jpg";
import img3 from "../../Images/03.jpg";
import costEffective from "../../Images/Cost-Effective Staffing.png";
import bridgeSkillGaps from "../../Images/Bridge Skill Gaps.png";
import flexibleScaling from "../../Images/Flexible Scaling.png";
import expiriencedSolutions from "../../Images/Experienced Solutions.png";
import simplifiedTalent from "../../Images/Simplified Talent Acquisition.png";
import lesserLegal from "../../Images/Lesser Legal Compliances.png";
import latestSoftwareTrends from "../../Images/latest software trends.png";
import img4 from "../../Images/04.jpg";
import img5 from "../../Images/05.jpg";
import googleImg from "../../Images/google_cloud_4x3_2x1_Small.jfif";
import awsImg from "../../Images/aws_4x3_2x1_Large.jfif";
import microsoftImg from "../../Images/Microsoft_4x3_2x1_Small.jfif";
import peopleIMg from "../../Images/full-shot-people-office.jpg";
import { useNavigate } from "react-router-dom";

const TeamAugmentation = () => {
   const navigate = useNavigate();
    
    const handleContactClick = () => {
      navigate("/contact");
    };
  return (
    <div>
      {/* Breadcrumb Section */}
      <section className="fir-sec">
        <div className="path">
          <div className="main-path">
            <span className="design-1">Home &gt; Services &gt;</span>
            <span className="design-2">Team Augmentation</span>
          </div>
        </div>
      </section>

      {/* Quality Assurance Section */}
      <section className="sen-sec">
        <div className="aligngment">
          <div className="qa-section">
            <div className="qa-text">Team Augmentation</div>
          </div>
        </div>
      </section>

      {/* QA Content Section */}
      {/* <section className="thi-sec">
        <div className="ali">
          <div className="qa-content">
            <div className="qa-left">
              <p></p>
            </div>
            <div className="qa-right">
              <p></p>
              <p></p>
            </div>
          </div>
        </div>
      </section> */}

      {/* Features Section */}
      <section className="fourth-sect">
        <div className="">
          <div className="features-section">
            <div className="feature-container ali">
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 1 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 2 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 3 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Responsive Content Section */}
      <section className="fifth-sect">
        <div className="ali">
          <h1 className="hea">
            Benefits of Hiring Resources through Novamic: -
          </h1>
          <div clasNames="responsive-section">
            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img src={costEffective} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">Cost-Effective Staffing:</h2>
                <p className="content-text">
                  Optimize your staffing costs by leveraging our team
                  augmentation services, ensuring you get the right talent
                  without the overhead of full-time hires.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container">
              <div className="responsive-image">
                <img src={bridgeSkillGaps} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">Bridge Skill Gaps:</h2>
                <p className="content-text">
                  Our team augmentation services effectively bridge skill gaps,
                  providing you with specialized talent that meets your
                  immediate project requirements.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img src={flexibleScaling} alt="Description of the image" />
              </div>

              <div className="responsive-content">
                <h2 className="content-heading">Flexible Scaling:</h2>
                <p className="content-text">
                  Easily scale your team up or down as needed, allowing you to
                  adapt to project demands and market conditions without the
                  long-term commitments.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container">
              <div className="responsive-image">
                <img
                  src={expiriencedSolutions}
                  alt="Description of the image"
                />
              </div>

              <div className="responsive-content  ">
                <h2 className="content-heading">Experienced Solutions:</h2>
                <p className="content-text">
                  Our experienced resources deliver solutions that add value to
                  specific industries or businesses, ensuring high-quality
                  outcomes.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img src={simplifiedTalent} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">
                  Simplified Talent Acquisition:
                </h2>
                <p className="content-text">
                  Ease the tedious job of your Talent Acquisition team by
                  obtaining quality engineers on an immediate basis, reducing
                  hiring time and effort.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>
            <div className="responsive-container">
              <div className="responsive-image">
                <img src={lesserLegal} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">Lesser Legal Compliances:</h2>
                <p className="content-text">
                  Ease the tedious job of your Talent Acquisition team by
                  obtaining quality engineers on an immediate basis, reducing
                  hiring time and effort.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>
            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img
                  src={latestSoftwareTrends}
                  alt="Description of the image"
                />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">
                  Onshore, Offshore and Hybrid models that scale to project
                  demands and latest software trends
                </h2>
                <p className="content-text">
                  Our IT staff augmentation services offer access to a broad and
                  skilled pool of technology talent and project managers,
                  eliminating the need for direct hiring, background checks, and
                  payroll administration. By using our services on a demand
                  basis, businesses maintain full management control and shape
                  projects according to their specific objectives. This allows
                  for ongoing oversight of project advancement and empowers
                  businesses to make final decisions on all project matters.
                  Moreover, our team augmentation model enables clients to hire
                  an extended team, as and when needed, contributing to
                  accelerated business operations and revenue growth.
                  <ul class="content-text">
                    <li>• React</li>
                    <li>• Node</li>
                    <li>• Angular</li>
                    <li>• Java</li>
                    <li>• Python</li>
                    <li>• Dot Net</li>
                    <li>• Low/Code No code (Appian, OutSystems)</li>
                    <li>• PHP</li>
                    <li>• Magento, Shopify, and WordPress</li>
                    <li>• DevOps Cloud</li>
                    <li>• Data Scientist</li>
                    <li>• Data Engineer</li>
                    <li>• ERP - SAP, Dynamics 365</li>
                    <li>• Salesforce</li>
                    <li>• ROR</li>
                    <li>• UI/UX</li>
                    <li>
                      • Mobile App (React Native, Flutter, iOS, and Android)
                    </li>
                  </ul>
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sixth-sect">
        <div className="ali pop">
          <div className="responsive-content-1">
            <span className="quotes">"</span>
            <p className="content-text-1">
              Working with Kyndryl to move to SAP S/4HANA was one of the
              smoothest projects I've ever been involved with. Even though we
              were contending with the impacts of COVID-19 throughout the
              engagement, Kyndryl mobilized its full global resources to help us
              stay on track and go live on time.
            </p>
            <span className="quotes">"</span>
          </div>
          <h1 className="hed-12">Rainer Steffl</h1>
          <p className="para-12">CIO, Mondi Group</p>
        </div>
      </section>

      <section className="seventh-sect">
        <div className="ali flexi">
          <div className="partnerships-text">
            <h2>Partnerships</h2>
            <p>Learn more about some of our key partnerships</p>
          </div>
          <div className="partnerships-logos">
            <img src={googleImg} alt="Google Cloud" />
            <img src={microsoftImg} alt="Microsoft" />
            <img src={awsImg} alt="AWS" />
          </div>
        </div>
      </section>

      <section className="Eighth-sec">
        <div className="ali">
          <div className="responsive-container-19">
            <div className="responsive-image">
              <img src={peopleIMg} alt="Description of the image" />
            </div>

            <div className="responsive-content ">
              <h2 className="kok">
                Get a 30-minute, no-cost strategy session with an Application
                services expert
              </h2>

              <a onClick={handleContactClick} className="content-button">
                Let's Talk &rarr;
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-sec">
        <div className="alingment-8588">
          <footer className="footer">
            <div className="footer-logo-section">
              <div className="footer-logo">
                <img src={logoImage} alt="Novamic Logo" />
              </div>
              <p className="footer-description">
                Novamic Technologies was established in order to develop
                creative digital platforms of technology and information.
              </p>
            </div>

            <div className="footer-links-section">
              <h4>Quick Links</h4>
              <ul className="jojo">
                <li>
                  <a href="#about">About us</a>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
                <li>
                  <a href="#career">Career</a>
                </li>
              </ul>
            </div>

            <div className="footer-newsletter-section">
              <h4>News Letter</h4>
              <p className="footer-newsletter-text">
                Don’t miss to subscribe to our new feeds, kindly fill the form
                below:
              </p>
              <form className="newsletter-form">
                <input
                  type="email"
                  className="newsletter-input"
                  placeholder="Email Address"
                  required
                />
                <button type="submit" className="newsletter-button">
                  →
                </button>
              </form>
              <div className="footer-social-links">
                <a href="#" target="_blank">
                  LinkedIn
                </a>
              </div>
            </div>
          </footer>
        </div>
      </section>
    </div>
  );
};

export default TeamAugmentation;
