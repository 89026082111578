import React from "react";
import "./AIML.css";
import costumerImg from "../../Images/costumer_6012670.png";
import logoImage from "../../Images/Logo.png";
import img1 from "../../Images/01.jpg";
import img2 from "../../Images/02.jpg";
import img3 from "../../Images/03.jpg";
import img4 from "../../Images/04.jpg";
import img5 from "../../Images/05.jpg";
import googleImg from "../../Images/google_cloud_4x3_2x1_Small.jfif";
import awsImg from "../../Images/aws_4x3_2x1_Large.jfif";
import microsoftImg from "../../Images/Microsoft_4x3_2x1_Small.jfif";
import peopleIMg from "../../Images/full-shot-people-office.jpg";
import { useNavigate } from "react-router-dom";


const AiMl = () => {

  const navigate = useNavigate();

  const handleContactClick = () => {
  navigate("/contact");
  };


  return (
    <div>
      {/* Breadcrumb Section */}
      <section className="fir-sec">
        <div className="path">
          <div className="main-path">
            <span className="design-1">Home &gt; Services &gt;</span>
            <span className="design-2">AI/ML</span>
          </div>
        </div>
      </section>

      {/* Quality Assurance Section */}
      <section className="sen-sec">
        <div className="aligngment">
          <div className="qa-section">
            <div className="qa-text">AI/ML</div>
          </div>
        </div>
      </section>

      {/* QA Content Section */}
      <section className="thi-sec">
        <div className="ali">
          <div className="qa-content">
            <div className="qa-left">
              <p>
                Novamic Technologies has delivered top-notch AI solutions to its
                clientele across various sectors, backed by professional AI
                engineers, who excel at combining technical expertise with
                industry-specific knowledge, and futuristic AI applications.
              </p>
            </div>
            <div className="qa-right">
              <p>
                We also help businesses in ensuring scalability and robust
                reliability of your AI models in production settings and
                enhancing your applications' intelligence without the need for
                extensive in-house AI resources.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="fourth-sect">
        <div className="">
          <div className="features-section">
            <div className="feature-container ali">
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 1 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 2 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
              <div className="feature-box">
                <div className="feature-icon">
                  <img src={costumerImg} alt="Feature 3 Icon" />
                </div>
                <h3 className="feature-title">
                  Modernize for better experiences
                </h3>
                <div className="feature-border"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Responsive Content Section */}
      <section className="fifth-sect">
        <div className="ali">
          <h1 className="hea">AI/ML</h1>
          <div clasNames="responsive-section">
            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img src={img1} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">AI/ML Consulting</h2>
                <p className="content-text">
                  Novamic Technologies enhances data's value by developing and
                  deploying custom AI and ML models, enabling you to automate
                  tasks, interpret data, and gain insights for better
                  decision-making. Our services play a crucial role in
                  automating operations and streamlining methods with quick
                  automation and reduced human errors. From identifying
                  opportunities to final reporting and consultation, our experts
                  guide businesses through the entire AI integration process,
                  fostering automation, efficiency, and customer satisfaction.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container">
              <div className="responsive-image">
                <img src={img2} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">
                  AI/ML Development and Integration
                </h2>
                <p className="content-text">
                  We optimize, containerize, and deploy your models using Docker
                  and Kubernetes, providing continuous monitoring to handle
                  increasing workloads with high availability. Our specialists
                  select, optimize, and integrate pre-built AI models and APIs
                  into your existing applications using top industry platforms
                  and tools. Detect anomalies before issues arise and achieve
                  root cause analysis for faster resolution. Our team leverages
                  Machine Learning (ML) techniques to analyze data from
                  monitoring tools and logs, enabling proactive and autonomous
                  IT operations management.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img src={img3} alt="Description of the image" />
              </div>

              <div className="responsive-content">
                <h2 className="content-heading">Deep Learning</h2>
                <p className="content-text">
                  Our services under the Deep Learning segment include utilizing
                  architectures to create intelligent systems such as advanced
                  recommendation engines and automated decision-making tools.
                  Our AI and ML developers specialize in designing neural
                  networks, training models, and optimizing them with top
                  frameworks like PyTorch and TensorFlow. This ensures high
                  accuracy for intricate tasks requiring human-level precision,
                  empowering businesses with better decision-making and
                  systematic operations.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container">
              <div className="responsive-image">
                <img src={img4} alt="Description of the image" />
              </div>

              <div className="responsive-content  ">
                <h2 className="content-heading">Generative Ai</h2>
                <p className="content-text">
                  Our generative AI strategic roadmap covers a bouquet of
                  services that include model selection, data requirements, and
                  infrastructure needs. We provide scalable and maintainable
                  generative AI solutions that adapt to the latest business
                  ecosystem. We ensure a seamless deployment of generative AI
                  models into your existing tech infrastructure and workflows,
                  while modernizing your digital products and saving time from
                  maintenance constraints. Our pre-trained models like GPT-4,
                  LLaMA-3, PaLM-2, Amazon Titan, and Cohere help businesses in
                  improving model accuracy, relevance, and usability.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img src={img5} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">LLM Development</h2>
                <p className="content-text">
                  Accelerate LLM adoption and simplify its implementation with
                  our comprehensive LLM development services. From data
                  annotation to model deployment, we manage every facet of LLM
                  implementation, ensuring your models are trained on superior
                  data, tailored to your specific use cases, and seamlessly
                  integrated into your existing technology infrastructure. Our
                  expertise in following a comprehensive Responsible AI
                  framework, data balancing and adversarial debiasing helps us
                  in optimizing models that ensure highest levels of performance
                  and accuracy of your LLM solutions.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>
            <div className="responsive-container">
              <div className="responsive-image">
                <img src={img4} alt="Description of the image" />
              </div>

              <div className="responsive-content  ">
                <h2 className="content-heading">NLP</h2>
                <p className="content-text">
                  Uncover actionable insights from unstructured text data to
                  enhance automation, personalization, and decision-making
                  processes through our Natural Language Processing (NLP)
                  services. Our services help clients harness deep learning
                  techniques involving linguistic and statistical algorithms to
                  analyze data patterns and improve program comprehension. We
                  help to integrate artificial intelligence, machine learning,
                  and linguistics to create systems capable of analyzing,
                  reporting, and communicating like humans. Our expert NLP
                  developers specialize in transforming structured or
                  semi-structured data into valuable insights across various
                  applications, including:
                  <ul>
                    <li>
                      <ul>
                        <li>o Sentiment analysis</li>
                      </ul>
                      <ul>
                        <li>o Machine translation</li>
                      </ul>
                      <ul>
                        <li>o Text generation</li>
                      </ul>
                      <ul>
                        <li>o Speech recognition</li>
                      </ul>
                      <ul>
                        <li>
                          o Spam detection
                          <br />
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p class="content-text">
                    Utilizing lexicon-based approaches alongside Recurrent
                    Neural Networks (RNNs) and Generative Adversarial Networks
                    (GANs), we tailor NLP solutions to meet your specific
                    business goals. Our process inculcates a systematic
                    understanding of the following steps: -
                  </p>
                  <ul>
                    <li>
                      <ul>
                        <li>
                          o Gain deeper insights into customer behavior through
                          sentiment analysis
                        </li>
                      </ul>
                      <ul>
                        <li>
                          o Identify, analyze, and process both structured and
                          unstructured data from extensive databases
                        </li>
                      </ul>
                      <ul>
                        <li>
                          o Stay informed about competitors’ activities and
                          strategies
                        </li>
                      </ul>
                      <ul>
                        <li>
                          o Discover product sources and perform vendor
                          comparisons
                        </li>
                      </ul>
                      <ul>
                        <li>
                          o Enhance the accuracy and efficiency of your
                          documentation process
                        </li>
                        <br />
                      </ul>
                    </li>
                  </ul>
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>

            <div className="responsive-container row-r">
              <div className="responsive-image">
                <img src={img5} alt="Description of the image" />
              </div>

              <div className="responsive-content ">
                <h2 className="content-heading">Computer Vision</h2>
                <p className="content-text">
                  Transform your unstructured visual data into actionable
                  insights with the help of our expert engineers who provide
                  specialized computer vision services. Our services include
                  Data Labelling, Object detection, Object Tracking, Image
                  Classification, Image Segmentation, Optical Character
                  Recognition, and visual search, among others. Our computer
                  vision consulting services are key to swift AI deployment and
                  help in generating precise and valuable insights. We design
                  computer vision solution architectures tailored to your
                  industry and business requirements. Our consultants ensure
                  seamless deployment and integration, aligning the solution
                  with specific business objectives.
                </p>
                <a onClick={handleContactClick} className="content-button">
                  Contact Us &rarr;
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sixth-sect">
        <div className="ali pop">
          <div className="responsive-content-1">
            <span className="quotes">"</span>
            <p className="content-text-1">
              Working with Kyndryl to move to SAP S/4HANA was one of the
              smoothest projects I've ever been involved with. Even though we
              were contending with the impacts of COVID-19 throughout the
              engagement, Kyndryl mobilized its full global resources to help us
              stay on track and go live on time.
            </p>
            <span className="quotes">"</span>
          </div>
          <h1 className="hed-12">Rainer Steffl</h1>
          <p className="para-12">CIO, Mondi Group</p>
        </div>
      </section>

      <section className="seventh-sect">
        <div className="ali flexi">
          <div className="partnerships-text">
            <h2>Partnerships</h2>
            <p>Learn more about some of our key partnerships</p>
          </div>
          <div className="partnerships-logos">
            <img src={googleImg} alt="Google Cloud" />
            <img src={microsoftImg} alt="Microsoft" />
            <img src={awsImg} alt="AWS" />
          </div>
        </div>
      </section>

      <section className="Eighth-sec">
        <div className="ali">
          <div className="responsive-container-19">
            <div className="responsive-image">
              <img src={peopleIMg} alt="Description of the image" />
            </div>

            <div className="responsive-content ">
              <h2 className="kok">
                Get a 30-minute, no-cost strategy session with an Application
                services expert
              </h2>

              <a onClick={handleContactClick} className="content-button">
                Let's Talk &rarr;
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-sec">
        <div className="alingment-8588">
          <footer className="footer">
            <div className="footer-logo-section">
              <div className="footer-logo">
                <img src={logoImage} alt="Novamic Logo" />
              </div>
              <p className="footer-description">
                Novamic Technologies was established in order to develop
                creative digital platforms of technology and information.
              </p>
            </div>

            <div className="footer-links-section">
              <h4>Quick Links</h4>
              <ul className="jojo">
                <li>
                  <a href="#about">About us</a>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
                <li>
                  <a href="#career">Career</a>
                </li>
              </ul>
            </div>

            <div className="footer-newsletter-section">
              <h4>News Letter</h4>
              <p className="footer-newsletter-text">
                Don’t miss to subscribe to our new feeds, kindly fill the form
                below:
              </p>
              <form className="newsletter-form">
                <input
                  type="email"
                  className="newsletter-input"
                  placeholder="Email Address"
                  required
                />
                <button type="submit" className="newsletter-button">
                  →
                </button>
              </form>
              <div className="footer-social-links">
                <a href="#" target="_blank">
                  LinkedIn
                </a>
              </div>
            </div>
          </footer>
        </div>
      </section>
    </div>
  );
};

export default AiMl;
